import WeatherGridEle from '../components/WeatherGridEle'
import {formatDate} from '../util/util'

function TheWeatherNetwork({ data }) {
    if (data.data.pollen_predictions.length < 1 ) {
        return <div></div>
    }
   
    var created_on = Date.parse(data.created_at);
    var my_map = new Map();
    for (var i = 0; i < data.data.pollen_predictions.length; i++)
    {
        var date = Date.parse(data.data.pollen_predictions[i].date);
        var block = {
            'label': data.data.pollen_predictions[i].Pollen,
            'level': data.data.pollen_predictions[i].Level
        }
        if (my_map.has(date)){
            my_map.get(date).push(block);
        }
        else {
            my_map.set(date, [block]);
        }
    }
    // sort the map
    const n = my_map.entries()
    const [forecasted_on, today_block] = n.next().value
    const [next_forecast1, next_block1] = n.next().value

    return <div>
        <h3>TheWeatherNetwork</h3>
        <br/>
        <h4>
            {formatDate(forecasted_on)}
        </h4>
        {today_block.map((value, index) => {
            return <WeatherGridEle value={value} key={index}/>
        })}
        <br/>
        <br/>
        < hr/>
        <h4>
            {formatDate(next_forecast1)}
        </h4>
        {next_block1.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}
        < hr/>
        <small>
            Forecasted on: {formatDate(forecasted_on)}
        </small>
        <br/>
        <small>
            Checked on: {formatDate(created_on)}
        </small>
    </div>
  }
  
  export default TheWeatherNetwork