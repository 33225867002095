import Head from 'next/head'
import styles from '../styles/Dashboard.module.css'
import WeatherGrid from '../components/WeatherGrid'
import Link from 'next/link'
import WeatherGridF from '../components/WeatherGridF'
import DateStuff from '../components/DateStuff'


export default function Dashboard(props){
  return (
    <div className={styles.container}>
      <Head>
        <title>Personalized Weather Forecast</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
            { new Date(props.date).toDateString() == new Date().toDateString() ? 'Today' :new Date(props.date).toDateString()}'s Forecast
        </h1>

      <div>
      </div>
        <DateStuff date={props.date}/>
        <WeatherGrid weathernetwork={props.weathernetwork} weatherbug={props.weatherbug} accuweather={props.accuweather}/>
   
      </main>

    </div>
  )
}


export async function getServerSideProps(context) {
    var date = null;
    var datepart = ''
    if (context.query.date != undefined) {
        date = context.query.date
        datepart = `&date=${date}`
    }
    else {
      var today = new Date()
      const options = {year: "numeric", month: 'numeric', day: 'numeric' } as const;
      var dateString = today.toLocaleDateString("en-US", options);
      date = dateString
    }

    // const api = 'http://localhost:8082'
    const api = 'http://v3.lisimia.com'
    // Fetch data from external API
    const res = await fetch(`${api}/air/api/datapoints?source=weathernetwork${datepart}`)
    const weathernetwork = await res.json()
    const res2 = await fetch(`${api}/air/api/datapoints?source=weatherbug${datepart}`)
    const weatherbug = await res2.json()
    const res3 = await fetch(`${api}/air/api/datapoints?source=accuweather${datepart}`)
    const accuweather = await res3.json()
    const res4 = await fetch(`${api}/air/api/datapoints?source=ambeedata_weather${datepart}`)
    const weather = await res4.json()
    const res5 = await fetch(`${api}/air/api/datapoints?source=ambeedata_weather_forecast${datepart}`)
    const weatherForecast = await res5.json()
    // Pass data to the page via props
    return { props: { weathernetwork, weatherbug, accuweather, weather, weatherForecast, date} }
  }
