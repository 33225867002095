import styles from '../styles/GridEle.module.css'

export default function WeatherGridEle(props) {
    if (props.value === null) {
        return <div></div>
    }
    var coloring = styles[props.value.level];
    if (!coloring) {
        if (props.value.level > 7)
            coloring = styles['High']
        else if (props.value.level > 3)
            coloring = styles['Moderate']
        else
            coloring = styles['Low']
        
    }

    return <div className={styles.box}>
        <div className={coloring}>{props.value.level} </div>
        <div className={styles.item}> {props.value.label} </div>
    </div>
  }