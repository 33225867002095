import TheWeatherNetwork from '../components/TheWeatherNetwork'
import WeatherBug from '../components/WeatherBug'
import AccuWeather from '../components/AccuWeather'
import styles from '../styles/Grid.module.css'

function WeatherGrid(props) {
    return <div className={styles.grid}>

        <div className={styles.card}>
            <TheWeatherNetwork data={props.weathernetwork} />
        </div>

        <div className={styles.card}>
            <WeatherBug data={props.weatherbug} />
        </div>

        <div className={styles.card}>
            <AccuWeather data={props.accuweather} />
        </div>

    </div>
}

export default WeatherGrid