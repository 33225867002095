import WeatherGridEle from '../components/WeatherGridEle'
import {formatDate} from '../util/util'

export default function AccuWeather({ data }) {
    var created_on = Date.parse(data.created_at);
    var today_block = [];
    today_block.push(
        {
            'label': 'Dust',
            'level': data.data[0].rating
        }
    )
    today_block.push(
        {
            'label': 'Dander',
            'level': data.data[0].rating
        }
    )

    var next_forecase_block = [];
    for (var i = 1; i < data.data.length; i++) {
        next_forecase_block.push(
            {
                'label': formatDate(Date.parse(data.data[i].date + ' 2021')),
                'level': data.data[i].rating
            }
        )
    }


    return <div>
        <h3>AccuWeather</h3>
        <br/>
        <h4> {formatDate(created_on)}</h4>
        {today_block.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}
        <br/>
        < hr/>
        <h4>Next Few Days</h4>
        {next_forecase_block.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}

        <br/>
        < hr/>
        <small>
            Checked on: {formatDate(created_on)}
        </small>
    </div>
  }