import React, { useState } from "react";
import { useRouter } from 'next/router'
import Link from "next/link";


import styles from '../styles/Dashboard.module.css'
import moment from "moment";
import DatePicker from "react-datepicker";

import { formatDate } from '../util/util'

import "react-datepicker/dist/react-datepicker.css";


function ndURL(date: string, daydiff: number, ss, router): void {
    var d = new Date(date)
    const today = moment();
    const tomorrow = moment(date).add(daydiff, 'days');
    ss(tomorrow.toDate())
    router.push("/dashboard?date=" + tomorrow.toISOString())
}



export default function DateStuff(props) {
    var m_date = null;
    // TODO this has a bug because the state isn't updated when props changes
    if (props.date)
        m_date = new Date(Date.parse(props.date))
    else
        m_date = new Date()
    const [startDate, setStartDate] = useState(m_date);
    const router = useRouter()

    return <div className={styles.dateStuff}>
        <p>
            Date: {formatDate(startDate)}
        </p>
        <  p>
            <button onClick={a => { ndURL(props.date, -1, setStartDate, router) }}>
                Back
            </button>
            <DatePicker
                selected={startDate} onChange={date => {
                    setStartDate(date);
                    router.push("/dashboard?date=" + date.toISOString())
                }}
            />
            <button onClick={a => { ndURL(props.date, 1, setStartDate, router) }}>
                Next
            </button>
        </p>
    </div>
}