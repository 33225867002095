import WeatherGridEle from '../components/WeatherGridEle'
import {formatDate} from '../util/util'

export default function WeatherBug({ data }) {
    var today_block = [];
    var created_on = Date.parse(data.created_at);
    const t = data.data.current.Location.periods[1];
    for (var i = 0; i < 3; i++) {
        today_block.push(
            {
                'label': t.Triggers[i].Name,
                'level': t.Index
            }
        )
    }
    var next_forecase_block = [];
    for (var i = 0; i < 3; i++) {
        next_forecase_block.push(
            {
                'label': data.data.current.Location.periods[2].Triggers[i].Name,
                'level': data.data.current.Location.periods[2].Index
            }
        )
    }
    var next_forecase_block2 = [];
    for (var i = 2; i < data.data.forecast.Location.periods.length; i++) {
        next_forecase_block2.push(
            {
                'label': formatDate(Date.parse(data.data.forecast.Location.periods[i].Period)),
                'level': data.data.forecast.Location.periods[i].Index
            }
        )
    }


    return <div>
        <h3>WeatherBug</h3>
        <br/>
        <h4>{formatDate(created_on)}</h4>
        {today_block.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}

        < hr/>
        <h4>{formatDate(new Date((new Date(created_on)).valueOf() + 1000*3600*24))}</h4>
        {next_forecase_block.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}
        <br/>
        < hr/>
        <h4>Next Few Days</h4>
        {next_forecase_block2.map((value, index) => {
            return <WeatherGridEle value={value}/>
        })}
        <br/>
        < hr/>
        <small>
            Checked on: {formatDate(created_on)}
        </small>
    </div>
  }